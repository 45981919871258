import React from "react";

const Features = () => (
  <section id="features" className="py-12 bg-gray-100 md:px-10 text-gray-700">
    <div className="container px-3 mx-auto">
      <h3 className="text-2xl font-bold text-center  mb-8">
        Why Choose CricketCraze?
      </h3>
      <div className="grid md:grid-cols-3 gap-8 ">
        <div
          onClick={() => window.open("https://wa.link/ggnow", "_blank")}
          className=" transition transform hover:scale-95 duration-500 text-center border-2 p-8 cursor-pointer shadow-xl bg-white rounded-lg"
        >
          <h4 className="text-lg font-bold mb-2">Real-Time Matches</h4>
          <p>
            Track live scores and build your team based on real-time player
            stats.
          </p>
        </div>
        <div
          onClick={() => window.open("https://wa.link/ggnow", "_blank")}
          className="text-center transition transform hover:scale-95 duration-500 border-2 p-8 cursor-pointer shadow-xl bg-white rounded-lg"
        >
          <h4 className="text-lg font-bold mb-2">Win Exciting Rewards</h4>
          <p>
            Compete with other players to win thrilling rewards and goodies.
          </p>
        </div>
        <div
          onClick={() => window.open("https://wa.link/ggnow", "_blank")}
          className="text-centertransition transform hover:scale-95 duration-500 border-2 p-8 cursor-pointer shadow-xl bg-white rounded-lg"
        >
          <h4 className="text-lg font-bold mb-2">Connect with Fans</h4>
          <p>Be part of a growing community of cricket enthusiasts.</p>
        </div>
      </div>
    </div>
  </section>
);

export default Features;
