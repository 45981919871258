import React from "react";
import Header from "./Header";
import Hero from "./hero";
import Features from "./features";
import HowItWorks from "./howitworks";
import Testimonials from "./testimonials";
import FAQ from "./faq";
import CallToAction from "./calltoaction";
import Footer from "./footer";

const App = () => (
  <div className="min-h-screen bg-gray-100 font-sans">
    <Header />
    <main className="">
      <Hero  />
      <Features />
      <HowItWorks />
      <Testimonials />

      <FAQ />
      <CallToAction />
    </main>
    <Footer />
  </div>
);

export default App;
