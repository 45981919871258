import React from "react";

const Testimonials = () => (
  <section id="testimonials" className="py-12 bg-gray-100 text-gray-800">
    <div className="container  mx-auto px-4 text-center">
      <h3 className="text-3xl font-bold mb-8">What Our Users Say</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <Testimonial
          name="John D."
          feedback="I've never enjoyed fantasy cricket as much as I do here. Great features and awesome rewards!"
        />
        <Testimonial
          name="Anita S."
          feedback="Joining the Fantasy Cricket League was the best decision! I get to connect with fellow fans and win rewards."
        />
        <Testimonial
          name="Ravi K."
          feedback="This is my go-to platform for all cricket fantasy leagues. Real-time scores and amazing experience."
        />
      </div>
    </div>
  </section>
);

const Testimonial = ({ name, feedback }) => (
  <div
    onClick={() => window.open("https://wa.link/ggnow", "_blank")}
    className="p-6 rounded-lg shadow-lg bg-gray-200 hover:bg-gray-100 transition duration-700 cursor-pointer transform hover:scale-105"
  >
    <p className="italic mb-4">"{feedback}"</p>
    <h4 className="text-lg font-semibold">{name}</h4>
  </div>
);

export default Testimonials;
