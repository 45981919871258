import React from "react";

const HowItWorks = () => (
  <section
    id="how-it-works"
    className="w-full md:h-[74vh] h-[80vh] z-0  relative bg-white text-white"
    style={{
      backgroundImage: "url('/SL_032921_41810_06.jpg')",
      backgroundSize: "cover",
    }}
  >
    <div className="absolute top-0 z-10  w-full h-screen bg-black opacity-35"></div>
    <div className="container relative z-20 mx-auto text-center px-3 ">
      <h3 className="md:text-5xl  text-white text-3xl pt-10 font-bold mb-8">
        How It Works
      </h3>
      <div className="grid md:grid-cols-3 gap-8">
        <div
          onClick={() => window.open("https://wa.link/ggnow", "_blank")}
          className="bachground-white/30 cursor-pointer  transition transform hover:scale-105 bg-tansparent border border-gray-100 backdrop-blur-lg p-8 rounded-lg shadow-lg"
        >
          <h4 className="text-lg md:text-xl text-white font-bold mb-2">
            1. Create Your Team
          </h4>
          <p className="text-gray-100">
            Pick your favorite players and form your ultimate team.
          </p>
        </div>
        <div
          onClick={() => window.open("https://wa.link/ggnow", "_blank")}
          className="bachground-white/30 cursor-pointer transition transform hover:scale-105  bg-tansparent  border border-gray-100 backdrop-blur-lg p-8 rounded-lg shadow-lg"
        >
          <h4 className="text-lg md:text-xl text-white font-bold mb-2">
            2. Join Contests
          </h4>
          <p className="text-gray-100">
            Participate in various contests based on upcoming matches.
          </p>
        </div>
        <div
          onClick={() => window.open("https://wa.link/ggnow", "_blank")}
          className="bachground-white/30 cursor-pointer transition transform hover:scale-105 bg-tansparent  backdrop-blur-lg border border-gray-100 p-8 rounded-lg shadow-lg"
        >
          <h4 className="text-lg md:text-xl text-white font-bold mb-2">
            3. Win & Celebrate
          </h4>
          <p className="text-gray-100">
            Track performance, top the leaderboard, and win prizes.
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default HowItWorks;
