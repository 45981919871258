import React from 'react';

const Header = () => (
  <header className="bg-gray-800 text-white md:px-10 py-4">
    <div className="container mx-auto flex justify-between items-center">
      <h1 className="text-2xl pl-2 font-bold">CricketCraze</h1>
      <nav className='md:block hidden'>
        <a href="#features" className="text-white hover:underline mx-4">Features</a>
        <a href="#how-it-works" className="text-white hover:underline mx-4">How It Works</a>
        <a href="https://wa.link/ggnow" className="md:text-black  text-white md:rounded-xl text-sm  mx-4 md:border-2 md:bg-white md:px-8 md:py-3">Get Started</a>
      </nav>
    </div>
  </header>
);

export default Header;
