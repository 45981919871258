import React from 'react';

const FAQ = () => (
  <section id="faq" className="py-12 bg-white text-gray-800">
    <div className="container mx-auto md:px-4">
      <h3 className="text-3xl font-bold text-center mb-8">Frequently Asked Questions</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <Question
          question="How do I create my fantasy team?"
          answer="It's simple! Just pick your favorite players for each match and create your dream team."
        />
        <Question
          question="Is there a cost to join the leagues?"
          answer="No, joining most leagues is free. You can compete and win prizes without any cost."
        />
        <Question
          question="How are scores calculated?"
          answer="Scores are calculated based on the real-time performance of players in the match."
        />
        <Question
          question="What kind of rewards can I win?"
          answer="You can win exciting prizes, merchandise, and exclusive access to special events."
        />
      </div>
    </div>
  </section>
);

const Question = ({ question, answer }) => (
  <div className="p-6 rounded-lg shadow-lg bg-gray-100 hover:bg-gray-200 cursor-pointer transition duration-300 cursor-pointer transform hover:scale-95"
  onClick={() => window.open('https://wa.link/ggnow', '_blank')}>
    <h4 className="text-lg font-semibold mb-2">{question}</h4>
    <p>{answer}</p>
  </div>
);

export default FAQ;
