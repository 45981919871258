import React from 'react';

const Hero = () => (
  <section className="bg-center bg-no-repeat h-[80vh] z-0  relative text-white py-20"
  style={{backgroundImage: "url('/ai crciket.jpg')", backgroundSize: 'cover'}}>
    <div className='absolute top-0 z-10 w-full h-[80vh] bg-black opacity-70'></div>
    <div className="container mx-auto relative z-20 mt-20 text-center px-3">
      <h2 className="md:text-6xl text-4xl md:text-white text-white font-bold mb-4">Build Your Dream Team</h2>
      <p className="text-lg  mb-6">
        Join millions of cricket fans and experience the thrill of fantasy sports.
        Show off your cricket knowledge and win amazing prizes!
      </p>
      <button
           onClick={() => window.open('https://wa.link/ggnow', '_blank')}
      className="bg-white cursor-pointer  text-black px-10 py-3 rounded-md font-bold hover:bg-gray-700">
        Start Now
      </button>
    </div>
  </section>
);

export default Hero;
