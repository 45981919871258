import React from 'react';

const CallToAction = () => (
  <section id="get-started" className="bg-yellow-400 text-gray-600 py-12">
    <div className="container mx-auto text-center">
      <h3 className="text-2xl font-bold mb-4">Ready to Start Your Journey?</h3>
      <p className="mb-6">Sign up today and start building your dream cricket team!</p>
      <button
      onClick={() => window.open('https://wa.link/ggnow', '_blank')}
      className="bg-gray-900 text-white px-10 py-3 cursor-pointer rounded-lg font-bold hover:bg-gray-400">
        Join Now
      </button>
    </div>
  </section>
);

export default CallToAction;
